import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import Header from '../components/BrandedKeywordsPage/Header'
import Protocols from '../components/BrandedKeywordsPage/Protocols'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      <Header header={data.header} />
      <Protocols protocols={data.protocols} />
    </Layout>
  )
}